#leftPane {
    background-color: #282c34;
    color:#fff;
    width:100%;
    padding:1em;
    margin:0;
    min-height:900px;
    padding-top:200px;
    padding-left:30px;

}
@media (max-width: 986px ){
    #leftPane {
        padding-top: 50px;
        padding-left: 0px;
        min-height: 150px;
        text-align:center;
    }
}
#rightPane {
    background-color: gainsboro;
    color:#282c34;
    padding:1em;
    min-height:900px;
}
@media (max-width: 986px ){
    #rightPane {
        padding-top: 0px;
        padding-left: 0px;
        min-height: 150px;
    }
}
.big-header {
    font-size: 50px;
}
.home-container {
    max-width:2000px !important;
}
.noGutter {
    padding-left:0 !important;
    padding-right:0 !important;
}
.home-container,.home-cells {
    background-color:#FFAF00;
}

.off-black {
    color:#282c34;
}
.off-white {
    color:gainsboro;
}
.off-gray {
    color:#777777;
}
.open-sans {
    font-family: 'Open Sans', sans-serif;
    font-weight:400;
}

.open-sans-bold {
    font-family: 'Open Sans', sans-serif;
    font-weight:600;
}

.noto-sans-jp {
    font-family: 'Noto Sans JP', sans-serif;
    font-weight:400;
}

.predige-rounded {
    font-family:'Predige Rounded',serif;
    font-weight:400;
}

.rel {
    position:relative;
}

.abs {
    position:absolute;
}

.hc-frame1 {
    top:0;
    left:0;
}

.hc-frame2 {
    top:0;
    left:0;
    display:none;
}
